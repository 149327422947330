<template>
  <div id="user-container" v-if="accessibleRoutes.user">
    <div id="user-btn-group" no-gutters>
      <div class="col-12 p-responsive">
        <div class="col-12">
          <v-btn-toggle>
            <router-link to="/users/list">
              <v-btn @click="createToggle()" :ripple="false" :class="`${toggleCreate == 1 ? 'active-menu' : 'inactive-menu'}`"> Create User </v-btn>
            </router-link>
            <router-link to="/users/reset-password">
              <v-btn @click="resetToggle()" :ripple="false" :class="`${toggleReset == 1 ? 'active-menu' : 'inactive-menu'}`"> Reset Password </v-btn>
            </router-link>
          </v-btn-toggle>
        </div>
      </div>
    </div>
    <div class="col-12 pt-0 pb-0">
      <router-view></router-view>
    </div>
  </div>
</template>

<style lang="scss" src="../../assets/css/users.scss"></style>

<script>
import { userProfileService } from "@/services";
export default {
  data() {
    return {
      toggleCreate: 0,
      toggleReset: 1,
      interval_browser: null,
    };
  },
  mounted(){
    this.interval_browser = setInterval(() => {
      userProfileService.getCurrentUserProfile();
    }, 5000);
  },
  destroyed() {
    clearInterval(this.interval_browser);
  },
  created() {
    if (this.$router.currentRoute.name == "User Management") {
      this.toggleReset = 1;
      this.toggleCreate = 0;
    }else{
      this.toggleReset = 0;
      this.toggleCreate = 1;
    }
  },
  methods:{
    createToggle(){
      this.toggleReset = 0;
      this.toggleCreate = 1;
    },
    resetToggle(){
      this.toggleReset = 1;
      this.toggleCreate = 0;
    }
  },
  computed: {
    accessibleRoutes: function () {
      return this.$store.getters.accessibleRoutes;
    }
  },
};
</script>
